import {useLocalstorage} from "~/use/localStorage/store";

const { persist } = useLocalstorage()
const state = reactive({
    isDarkMode: persist<Boolean>('isDarkMode', window.matchMedia('(prefers-color-scheme: dark)').matches)
})

const isDarkMode = computed(() => state.isDarkMode)

const setDarkMode = (newValue: boolean) => {
    state.isDarkMode = newValue
}

export const useDarkModeStore = () => ({
    isDarkMode,
    setDarkMode
})