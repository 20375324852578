
import cloneDeep from 'lodash/cloneDeep'
import type {Contract, FullContract, MapImage} from '@/models/contract'
import { useContractsDb } from '@/use/indexedDb/contractsDb'
import { DataType, RequestType } from '@/models/queue'
import { useLocalstorage } from '@/use/localStorage/store'
import { useQueueDb } from '@/use/indexedDb/queueDb'
import { generateUUID } from '@/use/uuid/util'
import { useQueueSizeStore } from '@/use/ui/queueSize'
import {
  cleanDraftContract, contractToFull,
  getContractCustomer,
  getDefaultContractData,
  isDraft
} from './util'
import {
  CONTRACT_STATES_SIGNED, CONTRACT_STATES_VH_ACCEPTED,
  CONTRACT_STATES_VH_DRAFT
} from '~/constants/contract'
import { useAxiosClient } from '~/use/axios/client'
import {ContractApi} from '~/gen/openapi/sblService'
import {compareObjectsGetDifferingKeys, getCurrentYearMonth, getMonthDifference} from "~/helpers/util";
import {MS_DELAY_CRM_TO_VH_SYNC, MS_INTERVAL_CLEAN_MODIFIED_TIMESTAMPS} from "~/use/contracts/constants";
import {computed} from "@vue/reactivity";
import {featureEnabled} from "~/helpers/features";

const { persist } = useLocalstorage()

/**
 * @message Displayable error message
 * @path Link path the error message will lead the user to
 * @pathName Displayable name
 * @anchor Hash anchor - must include #
 */
export interface ContractActivationError {
  message: string;
  path?: string
  pathName?: string
  anchor?: string
}

const state = reactive({
  currentContract: persist<FullContract>('currentContract', getDefaultContractData(-1, {}, {}, {}), 'idb'),
  currentContractReference: {},
  lastUpdated: persist<Date | null>('lastUpdatedContract', null),
  showNavigation: true,
  activationErrors: [] as ContractActivationError[],
  activationInProgress: false,
  currentContractExcludedFromForestFund: false,
  /**
   * When a contract last edited properties that needs syncing.
   * Activation should wait after these changes so CRM and VH
   * have a chance to catch up syncing first.
   *
   * Similar to Production Orders locking.
   * Prevents user getting errors caused by activation while sync is ongoing.
   * Should be replaced in the future™.
   * */
  modifiedPropsThatNeedSyncingTimestamps: persist(
      'contractModifiedPropsThatNeedSyncingTimestamps',
      {} as { [key: string]: number }
  )
})

/**
 * Cleans up outdated modifiedTimestamps
 */
function cleanModifiedTimestamps() {
  const aLittleWhileAgo = new Date().getTime() - MS_DELAY_CRM_TO_VH_SYNC

  state.modifiedPropsThatNeedSyncingTimestamps = {
    ...Object.fromEntries(
        Object.entries(state.modifiedPropsThatNeedSyncingTimestamps).filter(
            // Only include relevant timestamps
            ([_, lastModifiedTimestamp]) => lastModifiedTimestamp > aLittleWhileAgo
        )
    )
  }
}

// make sure to clean up local state to avoid cluttering localStorage too much
setInterval(cleanModifiedTimestamps, MS_INTERVAL_CLEAN_MODIFIED_TIMESTAMPS)

function touchModifiedPropsThatNeedSyncing(contractId: string, time?: number) {
  state.modifiedPropsThatNeedSyncingTimestamps = {
    ...state.modifiedPropsThatNeedSyncingTimestamps,
    [contractId]: time ?? new Date().getTime()
  }
}

const activationLockedUntil = computed(() => {
  const ts = state.modifiedPropsThatNeedSyncingTimestamps[currentContract.value.Id!]
  if (ts) {
    return ts + MS_DELAY_CRM_TO_VH_SYNC
  }
  return 0
})

const stopWatchingInitialCurrentContract = watch(() => state.currentContract, contract => {
  // setting initial currentContract from `persist` is async in case of storage === 'idb',
  // so need to watch it (only once) to set derived initial properties
  state.currentContractReference = contract

  state.currentContractExcludedFromForestFund =
    contract.ForestFundRate === 0
  stopWatchingInitialCurrentContract()
})

const currentContract = computed<FullContract>(() => state.currentContract)
const currentContractReference = computed(
  (): FullContract => state.currentContractReference
)
const activateValidationErrors = computed<ContractActivationError[]>(() => {
  const errors = []
  if (
    ![CONTRACT_STATES_SIGNED, CONTRACT_STATES_VH_DRAFT].includes(
      state.currentContract.Status!
    )
  ) {
    errors.push({message: 'ACTIVATION_ERROR_CONTRACT_NOT_READY'})
  }
  if (!state.currentContract.VsysNo) {
    errors.push({message: 'ACTIVATION_ERROR_MISSING_VSYS_NO'})
  }
  if (contractReferenceHasOutdatedPeriod.value && currentContractHasOutdatedPeriod.value) {
    errors.push({message: 'ACTIVATION_ERROR_OUTDATED_PERIOD', path: `/customers/${currentContract.value.ForestOwnerId}/${currentContract.value.PropertyId}/${currentContract.value.Id}/updatesigned`, anchor:'#periodSection', pathName: 'Endre periode'})
  }
  if (
    Array.isArray(currentContract.value.Details) &&
    currentContract.value.Details.some((detail) => !detail.TimberPriceListId)
  ) {
    errors.push({message:'ACTIVATION_ERROR_MUST_HAVE_PRICELISTS', path: `/customers/${currentContract.value.ForestOwnerId}/${currentContract.value.PropertyId}/${currentContract.value.Id}/updatesigned`, anchor:'#assortmentList', pathName: 'Legg til prisliste'})
  }
  if (
      !currentContract.value.TopplePlaces ||
      !currentContract.value.TopplePlaces.length
  ) {
    errors.push({message: 'ACTIVATION_ERROR_MUST_HAVE_TOPPLE_PLACE', path: `/customers/${currentContract.value.ForestOwnerId}/${currentContract.value.PropertyId}/${currentContract.value.Id}/updatesigned`, anchor:'#topplePlacesSection', pathName: 'Legg til henteplass'})
  }
  if (currentContract.value.UseLargeForestOwnerFlow) {
    if (!currentContract.value.ProductionOrders?.every((order) => order.Entrepreneur?.Id)) {
      errors.push({message: 'PRODUCTION_ORDER_MISSING_ENTREPRENEUR'})
    }
    if (!currentContract.value.EnvironmentReport?.SignedEnvironmentalReportForestOwner) {
      errors.push({message: 'ENVIRONMENTAL_REPORT_MUST_BE_SIGNED_BY_FOREST_OWNER'})
    }
  }
  return errors
})
const canBeActivated = computed(
  () => activateValidationErrors.value.length === 0
)
const activationErrors = computed(() =>
  activateValidationErrors.value.concat(state.activationErrors)
)
const activationInProgress = computed(() => state.activationInProgress)
const currentContractExcludedFromForestFund = computed(
  () => state.currentContractExcludedFromForestFund
)
const setCurrentContractExcludedFromForestFund = (val: boolean) => {
  state.currentContractExcludedFromForestFund = val
}

function hasPriceListChanges(newContract: FullContract, currentContract: FullContract) {
  const currentPriceListIds = (currentContract.Details || []).map(d => d.TimberPriceListId)
  const newPriceListIds = (newContract.Details || []).map(d => d.TimberPriceListId)
  if (currentPriceListIds.length !== newPriceListIds.length) {
    return true
  }
  return currentPriceListIds.some((id, i) => id !== newPriceListIds[i])
}

const currentContractHasPriceListChanges = computed(() => {
  return hasPriceListChanges(state.currentContract, state.currentContractReference)
})

const KEYS_TO_IGNORE_REGEX: RegExp[] = [
  /^Details\.\d+\.Percent$/,
  /^PriceListAllocationCode$/,
  /^ModifiedDate$/
];

function filterIgnoredKeys(keys: string[]): string[] {
  return keys.filter(key => !KEYS_TO_IGNORE_REGEX.some(regex => regex.test(key)));
}

const currentContractHasUnsavedChanges = computed<Boolean>(() => {
  const changes = compareObjectsGetDifferingKeys(currentContractReference.value, currentContract.value)
  const filteredChanges = filterIgnoredKeys(changes)

  return !!(filteredChanges.length)
})

const detailsMissingPriceInCurrentContract = computed(() => {
  return state.currentContract.Details?.filter(
    (detail) => detail.PriceType === undefined || !detail.TimberPriceListId
  ) || []
})

const detailsMissingQuantityInCurrentContract = computed(() => {
  return state.currentContract.Details?.filter((detail) => detail.Quantity === 0) || []
})

const currentContractShouldAllowMissingDetails = computed(() => {
  return state.currentContract.Status && state.currentContract.Status < CONTRACT_STATES_VH_ACCEPTED
})

const setCurrentContract = (contract: FullContract): Promise<Contract> => {
  setActivationErrors([])
  contract.ModifiedDate = contract.ModifiedDate ?? new Date().toISOString()
  state.currentContract = contract
  state.currentContractExcludedFromForestFund = contract.ForestFundRate === 0
  return Promise.resolve(contract)
}

const setCurrentContractReference = (contract: FullContract): void => {
  state.currentContractReference = contract
}

const clearCurrentContract = (): void => {
  state.currentContract = {}
  state.currentContractExcludedFromForestFund = false
}

const setNavigationVisibility = (visible: boolean): void => {
  state.showNavigation = !!visible
}

const setActivationErrors = (err: ContractActivationError[]): void => {
  state.activationErrors = err
}

const startActivation = () => {
  state.activationInProgress = true
}

const stopActivation = () => {
  state.activationInProgress = false
}

const addCurrentContractMapImages = (image: MapImage) => {
  const existing = state.currentContract.MapImages?.find(
    (i: MapImage) => i.Name === image.Name
  )
  if (!existing) {
    state.currentContract.MapImages?.push(image)
  } else {
    delete existing.Deleted
  }
}

const loadContract = async (id: string): Promise<FullContract> => {
  const { axiosClient } = useAxiosClient()
  const contractApi = new ContractApi(undefined, '', axiosClient.value)
  return (await contractApi.contractGetContract(id))?.data
}

const loadContractsIncremental = async (): Promise<Contract[]> => {
  const { insertContracts } = useContractsDb()
  const { axiosClient } = useAxiosClient()
  const contractApi = new ContractApi(undefined, '', axiosClient.value)
  const contracts = (await contractApi.contractGetContracts(state.lastUpdated ?? new Date(0)))?.data
  state.lastUpdated = new Date()
  await insertContracts(contracts)
  return contracts
}
const loadContracts = async (): Promise<Contract[]> => {
  const { insertContracts, removeDeletedContracts } = useContractsDb()
  const { axiosClient } = useAxiosClient()
  const contractsApi = new ContractApi(undefined, '', axiosClient.value)
  try {
    const contracts = (await contractsApi.contractGetContractList()).data
    state.lastUpdated = new Date()
    await insertContracts(contracts)
    await removeDeletedContracts(contracts)
    return contracts
  } catch (error) {
    console.error(error)
    throw error
  }
}

const getUrlAndMethod = (
  contract: FullContract
): { url: string; method: RequestType } => {
  let url: string
  let method: RequestType
  if (isDraft(contract)) {
    url = '/v1/contracts'
    method = RequestType.post
  } else {
    url = `/v1/contracts/${contract.Id}`
    method = RequestType.put
  }

  return {
    url,
    method
  }
}

const saveContract = async (contract: FullContract): Promise<FullContract> => {
  const draft = isDraft(contract)
  const cleanedContract = draft
    ? cleanDraftContract(contract)
    : contract
  try {
    const { axiosClient } = useAxiosClient()
    const contractApi = new ContractApi(undefined, '', axiosClient.value)
    if (draft) {
      return (await contractApi.contractCreateContract(cleanedContract))?.data
    } else {
      return (await contractApi.contractUpdateContract(cleanedContract.Id!, cleanedContract))?.data
    }
  } catch (error: any) {
    error.toString() === 'Error: Network Error' && await addContractToQueue(contract)
    console.error(error)
    throw error
  }
}

const addContractToQueue = async (contract: FullContract): Promise<void> => {
  const { url, method } = getUrlAndMethod(contract)
  const { queueDb } = useQueueDb()
  const { updateQueueSize } = useQueueSizeStore()
  const cleanedContract = isDraft(contract)
    ? cleanDraftContract(contract)
    : contract
  const customer = await getContractCustomer(contract)

  // Check if contract is already in queue
  // If in queue pass id as key to `setById`
  const queue = await queueDb.getAll()
  const existing = queue.find((item) => item.payload.TempId === contract.Id)

  await queueDb.setById({
    Id: existing?.Id || generateUUID(),
    url,
    date: new Date(),
    requestType: method,
    payload: cleanedContract,
    type: DataType.Contract,
    title: customer.Name
  })

  await updateQueueSize()
}

const activateContract = async (): Promise<boolean> => {
  startActivation()
  setActivationErrors([])
  if (!currentContract?.value?.Id) {
    setActivationErrors([{message: 'ERROR_NO_CURRENT_CONTRACT'}])
    return false
  }
  if (!canBeActivated) {
    setActivationErrors([{message: 'ACTIVATION_ERROR_CONTRACT_NOT_READY'}])
    return false
  }

  try {
    const { axiosClient } = useAxiosClient()
    const contractApi = new ContractApi(undefined, '', axiosClient.value)
    await contractApi.contractForestOperatorContractActivation(currentContract.value.Id)
    const updatedContract = await loadContract(currentContract.value.Id)
    await setCurrentContract(updatedContract)
    return true
  } catch (e: any) {
    if (
      typeof e === 'object' &&
      'response' in e &&
      typeof e.response === 'object' &&
      'data' in e.response
    ) {
      // normal http error (usually 406)
      if (typeof e.response.data === 'string') {
        console.error('Failed to activate contract: ', e)
        setActivationErrors([{message: e.response.data}])
      } else if (Array.isArray(e.response.data)) {
        setActivationErrors(e.response.data.map((d: any) => {
          return {message: d as string}
        }))
      } else {
        console.error('Unexpected response while activating contract: ', e)
        setActivationErrors([{message: 'ACTIVATION_ERROR_UNKNOWN'}])
      }
      return false
    } else {
      console.error('Unexpected error for activating contract: ', e)
      setActivationErrors([{message: 'ACTIVATION_ERROR_UNKNOWN'}])
      return false
    }
  } finally {
    stopActivation()
  }
}

const getContractFromApiFallBackToIndexedDb = async (id: string): Promise<FullContract | undefined> => {
  const { contractsDb } = useContractsDb()
  try {
    return await loadContract(id)
  } catch (e) {
    const contract = await contractsDb.getById(id)
    if (contract) {
      return contractToFull(contract)
    }
  }
}

const getContractFromIndexedDbFallbackToApi = async (id: string): Promise<FullContract | undefined> => {
  const { contractsDb } = useContractsDb()
  try {
    const contract = await contractsDb.getById(id)
    if (contract) {
      return contractToFull(contract)
    }
    return loadContract(id)
  } catch (e) {
    console.error(e)
    return undefined
  }
}

/**
 * Checks contract reference because changing period needs saving before we allow other changes.
 */
const contractReferenceHasOutdatedPeriod = computed<boolean>(() => {
  if (currentContractReference.value.Status !== CONTRACT_STATES_VH_DRAFT) {
    return false
  }

  const currentPeriod = getCurrentYearMonth()
  const diff = getMonthDifference('' + currentContractReference.value.Year + currentContractReference.value.LoggingMonth?.toString().padStart(2, '0'), currentPeriod)
  return diff > 0
})

const currentContractHasOutdatedPeriod = computed<boolean>(() => {
  if (currentContract.value.Status !== CONTRACT_STATES_VH_DRAFT) {
    return false
  }

  const currentPeriod = getCurrentYearMonth()
  const diff = getMonthDifference('' + currentContract.value.Year + currentContract.value.LoggingMonth?.toString().padStart(2, '0'), currentPeriod)
  return diff > 0
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useContractsStore = () => {
  const { contractsDb } = useContractsDb()
  const runtimeConfig = useRuntimeConfig()
  const tempCurrentContract = ref<FullContract>(cloneDeep(state.currentContract))
  const resetTempContract = () => {
    tempCurrentContract.value = cloneDeep(state.currentContract)
  }
  let tempCurrentContractDebounceTimer: NodeJS.Timeout | null = null
  const debounceTime = 300
  // Update temp contract when current contract changes
  watch(
    () => state.currentContract,
    () => {
      if (tempCurrentContractDebounceTimer) {
        clearTimeout(tempCurrentContractDebounceTimer)
      }
      tempCurrentContractDebounceTimer = setTimeout(
        () => (tempCurrentContract.value = cloneDeep(state.currentContract)),
        debounceTime
      )

      // If contract did not have VsysNo earlier, but now got it, add a time lock for activation
      const featureEnabledVsysNoContractActivationTimeLock = featureEnabled(runtimeConfig.public.FEATURE_ENABLE_VSYSNO_CONTRACT_ACTIVATION_TIME_LOCK)
      if (featureEnabledVsysNoContractActivationTimeLock &&
          currentContract.value.Status === CONTRACT_STATES_VH_DRAFT &&
          currentContract.value.Id &&
          currentContract.value.VsysNo) {
        contractsDb.getById(currentContract.value.Id).then((localContract) => {
          if (!localContract.VsysNo && currentContract.value.ModifiedDate) {
            contractsDb.setById(currentContract.value) // update local contract so VsysNo is no longer missing
            console.log('Contract received new VsysNo, setting time lock')
            const time = new Date(currentContract.value.ModifiedDate).getTime()
            touchModifiedPropsThatNeedSyncing(currentContract.value.Id!, time)
          }
        })
      }
    }
  )
  return {
    addContractToQueue,
    addCurrentContractMapImages,
    clearCurrentContract,
    currentContract,
    currentContractReference,
    currentContractHasUnsavedChanges,
    loadContract,
    loadContracts,
    loadContractsIncremental,
    resetTempContract,
    saveContract,
    setCurrentContract,
    setCurrentContractReference,
    setNavigationVisibility,
    tempCurrentContract,
    activateContract,
    activationErrors,
    activationInProgress,
    canBeActivated,
    startActivation,
    currentContractExcludedFromForestFund,
    setCurrentContractExcludedFromForestFund,
    currentContractHasPriceListChanges,
    getContractFromIndexedDbFallbackToApi,
    getContractFromApiFallBackToIndexedDb,
    detailsMissingPriceInCurrentContract,
    detailsMissingQuantityInCurrentContract,
    currentContractShouldAllowMissingDetails,
    touchModifiedPropsThatNeedSyncing,
    activationLockedUntil,
    contractReferenceHasOutdatedPeriod,
  }
}
