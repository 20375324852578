<script>

import {useDarkModeStore} from "~/use/ui/darkMode";

export default {
  setup() {
    onMounted(() => {
      const { isDarkMode } = useDarkModeStore()
      const htmlTag = document.documentElement
      
      const updateClass = (darkMode) => {
        if (darkMode) {
          htmlTag.classList.add('dark')
        } else {
          htmlTag.classList.remove('dark')
        }
      }

      watch(() => isDarkMode.value, () => {
            updateClass(isDarkMode.value)
          },
          {immediate: true}
      )
    })
  }
}
</script>
<template>
  <slot />
</template>
